<template>
  <div v-if="getCustomContent">
    <span v-html="getCustomContent"/>
  </div>
  <div v-else>
    <h1>
      {{$t('EthicsAndCopyrightPage.mainHeader')}}
    </h1>

    <secondary-block>
      <h2>
        {{$t('EthicsAndCopyrightPage.block1.header')}}
      </h2>
      <p>
        {{$t('EthicsAndCopyrightPage.block1.part1')}}
      </p>
      <p>
        <a style="text-decoration: none" href="https://publicationethics.org/resources">
          {{$t('EthicsAndCopyrightPage.block1.part2')}}
        </a>
      </p>
      <p>
        {{$t('EthicsAndCopyrightPage.block1.part3')}}
      </p>
      <ol>
        <li>{{$t('EthicsAndCopyrightPage.block1.part4')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part5')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part6')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part7')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part8')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part9')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part10')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part11')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part12')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part13')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part14')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part15')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block1.part16')}}</li>
      </ol>
    </secondary-block>

    <secondary-block>
      <h2>
        {{$t('EthicsAndCopyrightPage.block2.header')}}
      </h2>
      <p>
        {{$t('EthicsAndCopyrightPage.block2.part1')}}
      </p>
      <p>
        {{$t('EthicsAndCopyrightPage.block2.part2')}}
      </p>
      <p>
        <strong>
          {{$t('EthicsAndCopyrightPage.block2.part3')}}
        </strong>
      </p>
      <ol>
        <li>{{$t('EthicsAndCopyrightPage.block2.part4')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part5')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part6')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part7')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part8')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part9')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part10')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part11')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part12')}}</li>
        <li>{{$t('EthicsAndCopyrightPage.block2.part13')}}</li>
      </ol>
    </secondary-block>

    <secondary-block>
      <h2>
        {{$t('EthicsAndCopyrightPage.block3.header')}}
      </h2>
      <p>
        {{$t('EthicsAndCopyrightPage.block3.part1')}}
      </p>
      <p>
        {{$t('EthicsAndCopyrightPage.block3.part2')}}
      </p>
      <p>
        {{$t('EthicsAndCopyrightPage.block3.part3')}}
      </p>
      <p>
        <a style="text-decoration: none" href="http://creativecommons.org/licenses/by/3.0/legalcode">
          {{$t('EthicsAndCopyrightPage.block3.part4')}}
        </a>
      </p>
    </secondary-block>
  </div>
</template>

<script>
import config from "../../config.json"
import SecondaryBlock from "@/components/SecondaryBlock";
export default {
  name: "TheEthicsAndCopyright",
  components: {SecondaryBlock},

  data: () => ({
    customContentua: '',
    customContenten: ''
  }),

  computed: {
    getCustomContent () {
      if (this._i18n.locale === 'en') {
        return this.customContenten
      }

      return this.customContentua
    }
  },

  async mounted() {
    for (const lang of ['en', 'ua']) {
      try {
        const response = await fetch(`${config.NUFT_URL}/rawPages/ethics_${lang}.html`)
        if (response.status !== 200) {
          continue;
        }
        this[`customContent${lang}`] = await response.text()
      } catch (e) {
       console.log("No file")
      }
    }
  }
}
</script>

<style scoped>
h2{
  margin-bottom: 1%;
}
h1{
  margin-top: 3%;
  margin-bottom: 3%;
}
</style>