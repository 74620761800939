<template>
  <div>
    <header-main/>
    <main-body>
      <the-ethics-and-copyright/>
    </main-body>
  </div>
</template>

<script>
import MainBody from "@/components/MainBody";
import TheEthicsAndCopyright from "@/components/TheEthicsAndCopyright";
import HeaderMain from "@/components/HeaderMain";
export default {
  name: "Ethics",
  components: { TheEthicsAndCopyright, MainBody, HeaderMain }
}
</script>

<style scoped>

</style>